@import './css/variables.scss';
@import './css/colors.scss';

.imageLink {
  display: block;
  position: relative;
  margin: 0;
  text-decoration: none;

  > img {
    width: 100%;
  }
}

.iconContainer {
  position: absolute;
  top: $margin / 2;
  right: $margin / 2;
  display: flex;
}

.icon {
  fill: $white;
}
