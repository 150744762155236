.pressReleases {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;
}

.pressRelease {
  background-color: #262626;
  img {
    max-width: 100%;
    margin-bottom: 20px;
  }

  padding: 20px;

  > a {
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
}
