@import "./typography.scss";
@import "./colors.scss";
@import "./variables.scss";
@import "./forms.scss";

body {
  margin: 0;
  padding: 0;
  background-image: url(../images/bg.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  background-color: #282828;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $white;
}
