@import url('https://fonts.googleapis.com/css2?family=Biryani:wght@300&family=Crimson+Text&display=swap');

@import './colors.scss';
@import './variables.scss';

body {
  font-family: 'Biryani', sans-serif;
  color: $white;
  font-size: 17px;
  line-height: 26px;
}

h1,
h2 {
  font-family: 'Crimson Text', serif;
  color: $white;
}

h3 {
  font-weight: 800;
}

$base: 40px;

h1 {
  font-size: $base;
  line-height: $base * 0.9;
}

h2 {
  font-size: $base * 0.8;
  line-height: $base * 0.8 * 0.9;
}

body {
  font-size: 16px;
  line-height: 26px;
}

h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: $margin / 2;
}

p {
  margin-bottom: $margin / 2;
}

.sectionHeading {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
