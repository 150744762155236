@import './css/variables.scss';

.tiles {
  display: grid;
  grid-column-gap: $margin;
  grid-row-gap: $margin;
  grid-template-columns: 1fr;
}

@media (min-width: 500px) {
  .tiles {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 800px) {
  .tiles {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .tiles {
    grid-template-columns: repeat(4, 1fr);
  }
}
