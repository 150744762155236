@import './css/colors.scss';

.icon {
  fill: $white;
}

.formRow {
  display: flex;
  justify-content: space-between;
}

.errors {
  font-style: italic;
}

.button {
  cursor: pointer;

  &.disabled {
    background-color: $gray;
    cursor: not-allowed;
  }
}

@media (min-width: 600px) {
  .preorder {
    display: flex;
    // justify-content: center;
  }

  .image {
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 280px;
  }

  .form {
    max-width: 400px;
  }
}
