@import './css/variables.scss';

.gutter {
  width: $margin;
  height: $margin;
  flex-shrink: 0;
}

.halfGutter {
  width: $margin / 2;
  height: $margin / 2;
}
