@import './css/variables.scss';

.merch {
  img {
    max-width: 100%;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: auto;
  }
}
.merchItem {
  display: flex;
  flex-direction: column;
}
