@import "./css/variables.scss";

.logo {
  display: block;

  > img {
    width: 100%;
    max-width: 400px;
  }
}
