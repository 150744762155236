.menu {
  background-color: #444444;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;

  > button {
    align-self: flex-end;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
}

.toggle {
  position: absolute;
  top: 20px;
  right: 20px;
}
