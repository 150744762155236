.spotify {
  font-size: 50px;
  line-height: 44px;
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    font-family: 'Crimson Text', serif;
    display: flex;
    align-items: center;

    > svg {
      flex-shrink: 0;
      width: 100px;
      height: 100px;
      margin: 0 20px 20px 0;
    }

    &:hover {
      color: #257549;
      > svg {
        fill: #257549;
      }
    }
  }
}
