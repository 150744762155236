@import './colors.scss';

input[type='email'] {
  padding: 10px;
  border: 0;
  width: 100%;
}

button {
  margin: 0;
  padding: 10px 20px;
  border: 0;
  background-color: $copper;
  color: $white;
  display: flex;
  justify-content: space-between;
}

input,
button,
label {
  font-size: 17px;
  line-height: 26px;
}
