.fiftyFifty {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (min-width: 600px) {
  .fiftyFifty {
    flex-direction: row;
    > * {
      flex-grow: 1;

      &:first-child {
        flex-basis: calc(100% / 1.5);
      }

      &:nth-child(2) {
        flex-basis: calc(100% / 3);
      }
    }
  }
}
