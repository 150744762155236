@import "./css/typography.scss";
@import "./css/colors.scss";

.menuItems {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
    font-family: "Crimson Text", serif;
    color: $white;
    font-size: $base;
    line-height: $base * 0.9;
    padding: 20px;
  }
}
