@import './css/colors.scss';
@import './css/variables.scss';

.cta {
  background-color: $copper;
  color: $white;
  display: block;
  text-decoration: none;
  font-weight: 800;
  padding: $borderRadius $margin / 2 $borderRadius $margin / 2;
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  fill: $white;
}

.disabled {
  padding: $borderRadius $margin / 2 $borderRadius $margin / 2;
  background-color: #3d3d3d;
  font-style: italic;
}
