@import './css/colors.scss';
@import './css/variables.scss';

.social {
  margin-top: 60px;

  h2 {
    color: $white;
  }

  a {
    text-decoration: none;
    color: $white;

    &:hover {
      color: $copper;
      > h2 {
        color: $copper;
      }

      .icon {
        fill: $copper;
      }
    }
  }
}

.icon {
  fill: $white;
}

.iconHeading {
  display: flex;
  align-items: center;
}

// @media (min-width: 600px) {
//   .social {
//     display: flex;
//     justify-content: center;
//     gap: 40px;

//     > a {
//       flex-shrink: 0;
//       flex-basis: 25%;
//     }
//   }
// }
